// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beacon-js": () => import("./../../../src/pages/beacon.js" /* webpackChunkName: "component---src-pages-beacon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-mailing-list-confirm-js": () => import("./../../../src/pages/mailing-list-confirm.js" /* webpackChunkName: "component---src-pages-mailing-list-confirm-js" */),
  "component---src-pages-mailing-list-thanks-js": () => import("./../../../src/pages/mailing-list-thanks.js" /* webpackChunkName: "component---src-pages-mailing-list-thanks-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tokenomics-js": () => import("./../../../src/pages/tokenomics.js" /* webpackChunkName: "component---src-pages-tokenomics-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-wallet-terms-js": () => import("./../../../src/pages/wallet-terms.js" /* webpackChunkName: "component---src-pages-wallet-terms-js" */),
  "component---src-pages-wallets-js": () => import("./../../../src/pages/wallets.js" /* webpackChunkName: "component---src-pages-wallets-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-press-list-template-js": () => import("./../../../src/templates/press-list-template.js" /* webpackChunkName: "component---src-templates-press-list-template-js" */),
  "component---src-templates-press-post-js": () => import("./../../../src/templates/press-post.js" /* webpackChunkName: "component---src-templates-press-post-js" */)
}

